<!--  -->
<template>
  <div class="treebody">
    <div v-for="(item, index) in treedata" :key="index" class="treeRow leftRow">
      <div class="treeRows" :class="parent == item.id ? 'fontHover' : ''">
        <i
          v-if="item.children && item.children.length > 0"
          :class="item.opens ? 'fold' : 'unfold'"
          @click="open(item, index)"
        ></i>
        <i v-else class="fold"></i>
        <span @click="trigger(item, '1')">{{ item.name }}</span>
        <div class="left_line"></div>
        <div
          class="lower_line"
          v-if="item.children && item.children.length > 0 && item.opens"
        ></div>
        <div class="treeMore">
          <el-dropdown trigger="click" @command="trigger(item, $event)">
            <span class="el-dropdown-link">
              <span class="el-icon-more"></span>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="2">添加下级</el-dropdown-item>
              <el-dropdown-item command="3">修改</el-dropdown-item>
              <el-dropdown-item command="4">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="line1" v-if="treedata.length - 1 == index"></div>
      <div class="son">
        <div
          class="cover_line"
          v-if="treedata.length - 1 == index && level"
        ></div>
        <subdirectory
          v-if="item.children && item.children.length > 0 && item.opens"
          :level="item"
          :parent="parent"
          :treedata="item.children"
          @trigger="trigger"
        ></subdirectory>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "subdirectory",
  props: ["treedata", "parent", "level"],
  data() {
    return {};
  },
  methods: {
    open(item) {
      item.opens = !item.opens;
    },
    trigger(item, num) {
      this.$emit("trigger", item, num);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./css/tree.css";
</style>

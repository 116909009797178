<template>
  <div class="allTemplate">
    <div class="templateLeft" :style="{ height: treeHeight }">
      <div class="company">
        <span class="el-icon-caret-bottom"></span>
        {{ company.name }}
      </div>
      <div class="department">
        <treelist
          v-if="treeData && treeData.length > 0"
          :parent="parent"
          :treedata="treeData"
          @trigger="trigger"
        ></treelist>
      </div>
      <div class="footAdd" @click="addTree()">新增一级部门</div>
    </div>
    <div class="templateRight">
      <div class="searchflex">
        <div class="searchLeft">
          <div class="searchInput">
            <el-input
              size="mini"
              v-model="search"
              @input="getSearch()"
              placeholder="请输入内容"
              suffix-icon="el-icon-search"
            ></el-input>
          </div>
        </div>
        <div class="searchRight">
          <img
            src="@/assets/image/add_new.png"
            v-if="treeData && treeData.length > 0"
            class="addIcon"
            @click="add()"
          />
        </div>
      </div>
      <yh-table
        tableBorder
        :tableHeight="tableHeight"
        :tableLoading="tableLoading"
        :tableHead="tableHead"
        :tableData="tableData"
      >
        <template #tableRow="{ row, item }">
          <span>{{ row[item.prop] }}</span>
        </template>
        <el-table-column label="操作" width="300" align="center" fixed="right">
          <template slot-scope="scope">
            <yh-popover
              popoverTitle="变更部门"
              @savePopUp="edit(scope.row)"
            ></yh-popover>
            <yh-popover
              popoverOnly="1"
              popoverTitle="删除"
              @savePopUp="del(scope.row)"
            >
              确定删除该信息吗？
            </yh-popover>
          </template>
        </el-table-column>
      </yh-table>
      <div class="pages">
        <el-pagination
          :current-page="page"
          :total="total"
          :page-size="size"
          :page-sizes="[20, 30, 40, 50]"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 新增编辑 -->
    <yh-dialog :dialogObj="departmentObj">
      <yh-form
        ref="formName"
        labelWidth="110px"
        :formRule="departmentRule"
        :formHead="departmentHead"
        :formData="departmentData"
      ></yh-form>
      <template #footer>
        <el-button
          size="mini"
          type="primary"
          v-if="prepare"
          @click="departmentSubmit()"
        >
          保 存
        </el-button>
        <el-button size="mini" type="primary" v-else> 保 存 </el-button>
      </template>
    </yh-dialog>
    <!-- 新增编辑 -->
    <yh-dialog :dialogObj="dialogObj">
      <yh-form
        ref="formName"
        labelWidth="110px"
        :formRule="formRule"
        :formHead="formHead"
        :formData="formData"
      ></yh-form>
      <template #footer>
        <el-button size="mini" type="primary" v-if="prepare" @click="submit()">
          保 存
        </el-button>
        <el-button size="mini" type="primary" v-else> 保 存 </el-button>
      </template>
    </yh-dialog>
  </div>
</template>
<script>
import treelist from "../component/branchTree";
import { listRecursion } from "@/utils/convert";
export default {
  components: {
    treelist,
  },
  data() {
    return {
      treeHeight: "",
      company: JSON.parse(sessionStorage.getItem("userinfo")),
      // 部门列表
      parent: "",
      treeData: [],
      // 部门新增修改
      departmentObj: {
        dialogTitle: "新增",
        dialogOpen: false,
        dialogWidth: "500px",
      },
      departmentRule: {
        name: [{ required: true, message: "请输入部门名称", trigger: "blur" }],
      },
      departmentHead: [
        {
          comp: "el-input",
          label: "部门名称",
          prop: "name",
          widthSize: 1,
        },
      ],
      departmentData: {
        id: undefined,
        name: undefined,
        parentId: undefined,
      },

      // 表格
      search: "",
      tableLoading: false,
      tableHeight: "100px",
      tableHead: [
        {
          prop: "name",
          label: "姓名",
          fixed: false,
          minWidth: "120",
        },
        {
          prop: "email",
          label: "邮箱",
          fixed: false,
          minWidth: "120",
        },
        {
          prop: "phone",
          label: "手机号",
          fixed: false,
          minWidth: "120",
        },
      ],
      tableData: [],
      page: 1,
      size: 20,
      total: 0,

      dialogObj: {
        dialogTitle: "关联用户",
        dialogOpen: false,
        dialogWidth: "500px",
      },
      formRule: {
        departmentId: [
          { required: true, message: "请选择部门", trigger: "change" },
        ],
        userName: [
          { required: true, message: "请选择用户信息", trigger: "change" },
        ],
      },
      formHead: [
        {
          comp: "el-select",
          sonComp: "el-option",
          label: "部门",
          prop: "departmentId",
          widthSize: 1,
          data: [],
        },
        {
          comp: "el-input",
          label: "用户名/手机号",
          prop: "userName",
          widthSize: 1,
        },
      ],
      formData: {
        id: undefined,
        userName: undefined,
        departmentId: undefined,
        tenantId: undefined,
      },

      prepare: true,
    };
  },
  created() {
    this.getHeight();
    window.addEventListener("resize", this.getHeight);
  },
  mounted() {
    this.getDepartments();
  },
  methods: {
    // 部门
    getDepartments() {
      this.treeData = [];
      this.$axios.get("departments", {}, (res) => {
        const status = res.status;
        if (status.toString().substring(0, 1) == 2) {
          this.formHead[0].data = JSON.parse(JSON.stringify(res.data));
          this.treeData = listRecursion(res.data);
          if (!this.parent) {
            if (this.treeData && this.treeData.length > 0) {
              this.parent = this.treeData[0].id;
            }
          }
          this.getData();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    trigger(item, num) {
      if (num == "1") {
        this.parent = item.id;
        this.getData();
      } else if (num == "2") {
        this.addTree(item);
      } else if (num == "3") {
        this.editTree(item);
      } else if (num == "4") {
        this.delTree(item);
      }
    },
    addTree(row) {
      if (row && row.id) {
        this.departmentData = {
          id: undefined,
          name: undefined,
          parentId: row.id,
        };
      } else {
        this.departmentData = {
          id: undefined,
          name: undefined,
          parentId: null,
        };
      }
      this.departmentObj.dialogOpen = true;
      this.departmentObj.dialogTitle = "新增";
    },
    editTree(row) {
      this.departmentData = {
        id: row.id,
        name: row.name,
        parentId: row.parentId,
      };
      this.departmentObj.dialogOpen = true;
      this.departmentObj.dialogTitle = "修改";
    },
    delTree(row) {
      this.$axios.jsonPost("departmentsDel", { id: row.id }, (res) => {
        var status = res.status;
        if (status.toString().substring(0, 1) == 2) {
          this.$message.success("删除成功");
          this.getDepartments();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    departmentSubmit() {
      this.$refs.formName.validate((valid) => {
        if (valid) {
          this.prepare = false;
          if (this.departmentData.id) {
            this.$axios.jsonPost(
              "departmentsEdit",
              {
                id: this.departmentData.id,
                name: this.departmentData.name,
                parentId: this.departmentData.parentId,
              },
              (res) => {
                this.prepare = true;
                var status = res.status;
                if (status.toString().substring(0, 1) == 2) {
                  this.departmentObj.dialogOpen = false;
                  this.$message.success("修改成功");
                  this.resetForm();
                  this.getDepartments();
                } else {
                  this.$message.error(res.data.message);
                }
              }
            );
          } else {
            this.$axios.jsonPost(
              "departments",
              {
                name: this.departmentData.name,
                parentId: this.departmentData.parentId,
              },
              (res) => {
                this.prepare = true;
                console.log(res, 1111);
                var status = res.status;
                if (status.toString().substring(0, 1) == 2) {
                  this.departmentObj.dialogOpen = false;
                  this.$message.success("添加成功");
                  this.resetForm();
                  this.getDepartments();
                } else {
                  this.$message.error(res.data.message);
                }
              }
            );
          }
        } else {
          this.$message.error("请填写必填项");
        }
      });
    },
    resetForm() {
      if (this.$refs.formName) {
        this.$refs.formName.resetFields();
      }
    },
    // 人员
    getSearch() {
      this.page = 1;
      this.getData();
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
    getData() {
      this.$axios.get(
        "employees",
        {
          perPage: this.size,
          page: this.page,
          q: this.search,
          departmentId: this.parent,
          tenantId: this.company.tenants[0].id,
        },
        (res) => {
          const status = res.status;
          if (status.toString().substring(0, 1) == 2) {
            this.tableData = res.data;
            this.total = Number(res.headers["x-total-count"]);
          } else {
            this.$message.error(res.data.message);
          }
        }
      );
    },
    add() {
      this.formData = {
        userName: undefined,
        departmentId: this.parent,
        tenantId: this.company.tenants[0].id,
      };
      this.formHead[1].noShow = false;
      this.dialogObj.dialogTitle = "关联用户";
      this.dialogObj.dialogOpen = true;
    },
    edit(row) {
      this.formData = {
        id: row.id,
        departmentId: this.parent,
      };
      this.formHead[1].noShow = true;
      this.dialogObj.dialogTitle = "变更部门";
      this.dialogObj.dialogOpen = true;
    },
    del(row) {
      this.$axios.jsonPost("employeesDel", { id: row.id }, (res) => {
        var status = res.status;
        if (status.toString().substring(0, 1) == 2) {
          this.$message.success("删除成功");
          this.getData();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    submit() {
      this.$refs.formName.validate((valid) => {
        if (valid) {
          this.prepare = false;
          if (this.formData.id) {
            this.$axios.jsonPost(
              "employeesEdit",
              {
                id: this.formData.id,
                departmentId: this.formData.departmentId,
              },
              (res) => {
                this.prepare = true;
                var status = res.status;
                if (status.toString().substring(0, 1) == 2) {
                  this.dialogObj.dialogOpen = false;
                  this.$message.success("修改成功");
                  this.resetForm();
                  this.getData();
                } else {
                  this.$message.error(res.data.message);
                }
              }
            );
          } else {
            this.$axios.get(
              "usersDetail",
              { id: this.formData.userName },
              (res) => {
                this.prepare = true;
                const status = res.status;
                if (status.toString().substring(0, 1) == 2) {
                  this.prepare = false;
                  this.$axios.jsonPost(
                    "employees",
                    {
                      userId: res.data.id,
                      departmentId: this.formData.departmentId,
                      tenantId: this.formData.tenantId,
                    },
                    (res1) => {
                      this.prepare = true;
                      var status1 = res1.status;
                      if (status1.toString().substring(0, 1) == 2) {
                        this.dialogObj.dialogOpen = false;
                        this.$message.success("关联成功");
                        this.resetForm();
                        this.getData();
                      } else {
                        this.$message.error(res1.data.message);
                      }
                    }
                  );
                } else {
                  this.$message.error(res.data.message);
                }
              }
            );
          }
        } else {
          this.$message.error("请填写必填项");
        }
      });
    },
    getHeight() {
      this.tableHeight = window.innerHeight - (50 + 40 + 60 + 30 + 43) + "px";
      this.treeHeight = window.innerHeight - (50 + 40 + 35) + "px";
    },
  },
};
</script>
<style lang="scss" scoped>
.allTemplate {
  display: flex;
  .templateLeft {
    width: 230px;
    border: 1px solid #ccc;
    .company {
      height: 55px;
      padding: 0 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #666;
      background: linear-gradient(180deg, #fff, #f1f1f1);
      span {
        margin-right: 3px;
      }
    }
    .department {
      height: calc(100% - 100px);
      padding-right: 5px;
      box-sizing: border-box;
      background: #f6f7fc;
      overflow-y: auto;
    }
    .footAdd {
      width: 100%;
      height: 45px;
      border-top: 1px solid #ccc;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #666;
      background: #f6f7fc;
      cursor: pointer;
    }
  }
  .templateRight {
    width: calc(100% - 240px);
    margin-left: 10px;
    .pages {
      text-align: right;
      padding: 5px 0;
      border: 1px solid #ddd;
    }
  }
}
</style>

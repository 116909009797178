<!-- 部门左侧 -->
<template>
  <div class="treebody">
    <div v-for="(item, index) in list" :key="index" class="treeRow">
      <div class="treeRows" :class="parent == item.id ? 'fontHover' : ''">
        <i
          v-if="item.children && item.children.length > 0"
          :class="item.opens ? 'fold' : 'unfold'"
          @click="open(item, index)"
        ></i>
        <i v-else class="fold"></i>
        <span @click="trigger(item, '1')">{{ item.name }}</span>
        <div class="upper_line" v-if="index != 0"></div>
        <div class="lower_line" v-if="list.length - 1 != index"></div>
        <div
          class="lower_line"
          v-if="
            list.length - 1 == index &&
            item.children &&
            item.children.length > 0 &&
            item.opens
          "
        ></div>
        <div class="treeMore">
          <el-dropdown trigger="click" @command="trigger(item, $event)">
            <span class="el-dropdown-link">
              <span class="el-icon-more"></span>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="2">添加下级</el-dropdown-item>
              <el-dropdown-item command="3">修改</el-dropdown-item>
              <el-dropdown-item command="4">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="line" v-if="list.length - 1 == index"></div>
      <subdirectory
        v-if="item.children && item.children.length > 0 && item.opens"
        :parent="parent"
        :treedata="item.children"
        :level="treedata.length - 1 == index ? true : false"
        @trigger="trigger"
      ></subdirectory>
    </div>
  </div>
</template>

<script>
import subdirectory from "./subdirectory";
export default {
  props: ["treedata", "parent"],
  data() {
    return {
      level: true,
      list: [],
    };
  },
  components: {
    subdirectory,
  },
  mounted() {
    this.list = this.recursion(this.treedata);
  },
  methods: {
    open(item) {
      item.opens = !item.opens;
    },
    trigger(item, num) {
      this.$emit("trigger", item, num);
    },
    // 递归
    recursion(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].children && data[i].children.length > 0) {
          this.$set(data[i], "opens", true);
          this.recursion(data[i].children);
        } else {
          this.$set(data[i], "opens", true);
        }
      }
      return data;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./css/tree.css";
</style>
